<template>
    <div>
        <input ref="fileInput" class="d-none" type="file" @change="onFileChange" :accept="mimeTypes"/>
        <v-text-field
            placeholder="Examinar"
            outlined dense
            hint="Documentos: .jpg, .png, .doc, .docx, .bmp, .jpeg, .gif. Tamaño máximo 4MB)"
            persistent-hint
            readonly
            v-model="currentFilename"
            prepend-inner-icon="$upload"
            append-icon="mdi-magnify"
            @click="pickFile"
        />
    </div>
</template>

<script>
export default {
    name: "UploadExam",
    props: {
        relativePath: {
            type: String,
            default: 'files'
        },
        filename: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            file: null,
            currentFilename: '',
            megabyte: 1048576,
            mimeTypes: 'image/jpeg, image/png, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, image/bmp, image/gif',
            limit: 4,
        }
    },
    computed: {
        limitSize(vm = this) {
            return vm.limit * vm.megabyte
        }
    },
    methods: {
        reset() {
            const vm = this
            vm.file = null
            vm.$refs.fileInput.value = null
        },
        pickFile() {
            const vm = this
            vm.$refs.fileInput.click()
        },
        onFileChange(evt) {
            const vm = this
            const file = evt.target.files[0]
            if (!file) return;
            if (file.size > vm.limitSize) return vm.toast(`Tamaño maximo es de 4mb`, 'error')
            vm.file = file
            vm.$emit('change', vm.file)
        },
    },
    watch: {
        filename(val) {
            this.currentFilename = val
        },
        file(val) {
            this.currentFilename = val?.name || ''
        }
    }
}
</script>

<style scoped>

>>> .v-input__control {
    background: #FFFFFF;
    border-radius: 12px;
}

</style>